.loader-container {
    padding: 0;
    margin: 0;
    background-color: #000000;
    text-align: center;
    height: 100vh;
    font-family: 'lato';
    font-weight: 100;
}

.wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.circle {
    display: inline-block;
    width: 15px;
    height: 15px;
    background-color: #ffffff;
    border-radius: 50%;
    animation: loading 1.5s cubic-bezier(.8, .5, .2, 1.4) infinite;
    transform-origin: bottom center;
    position: relative;
}

@keyframes loading {
    0% {
        transform: translateY(0px);
        background-color: #ffffff;
    }

    50% {
        transform: translateY(50px);
        background-color: #676767;
    }

    100% {
        transform: translateY(0px);
        background-color: #ffffff;
    }
}

.circle-1 {
    animation-delay: 0.1s;
}

.circle-2 {
    animation-delay: 0.2s;
}

.circle-3 {
    animation-delay: 0.3s;
}

.circle-4 {
    animation-delay: 0.4s;
}

.circle-5 {
    animation-delay: 0.5s;
}

.circle-6 {
    animation-delay: 0.6s;
}

.circle-7 {
    animation-delay: 0.7s;
}

.circle-8 {
    animation-delay: 0.8s;
}