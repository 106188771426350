.home {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    justify-content: flex-start;
}

.home_head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 6rem;
    width: auto;
}

.home_head_name {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: left;
    background-size: cover;
    text-align: left;
}

.home_head_name h1 {
    width: 350px;
    height: 38px;
    left: 182px;
    top: 28px;

    font-family: 'Italiana';
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 38px;
    text-transform: uppercase;
    color: black;
}

.home_head_links {
    display: flex;
    flex-direction: row;
}

.home_head_links h1 {
    margin: 0 0.5rem;
    width: auto;
    height: 38px;
    color: rgb(114, 114, 114);
    fill: rgb(114, 114, 114);
}

.home_head_links h1:hover {
    color: black;
    fill: black;
    transition: transform 0.25s ease-in-out;
}

.home_categories {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    position: relative;
    padding: 0 2rem;
    /* width: 100%; */
    height: auto;
    margin: 0 auto;
}

.home_categories_inner {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    overflow-x: auto;
    overflow-y: hidden;
    scroll-behavior: smooth;
    
    /* width: 80%; */
}

.home_categories_inner::-webkit-scrollbar {
    display: none;
}

.home_categories_arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 1;
}

.home_categories_arrow.left {
    left: 0;
}

.home_categories_arrow.right {
    right: 0;
}

.home_categories p {
    font-family: 'Italiana';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    color: black;
    padding: 0 1rem;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
}

.home_title {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.home_gallery {
    /* display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    max-width: 1500px;
    margin : 0 15%; */

    grid: auto / auto;
    grid-gap: 0rem;

    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-auto-rows: minmax(300px, 1fr);
    grid-auto-flow: dense;

    align-items: center;
    justify-items: center;
    margin: 0 auto;
    width: 80%;
    height: 100%;
}

.home_footer {
    margin-top: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 1rem 0rem;
    padding-bottom: 2rem;
    width: auto;
}

.home_footer p {
    margin: 0;
    width: auto;
    color: black;

    font-family: 'Italiana';
    font-style: normal;

    font-weight: 600;
    font-size: 20px;
    line-height: 38px;
    text-transform: uppercase;

    cursor: pointer;

    text-align: center;
    white-space: pre-wrap;
}

.home_footer a {
    text-decoration: none;
}

.home_footer p:hover {
    color: rgb(114, 114, 114);
    transition: transform 0.25s ease-in-out;
}


/* categories selection */
.active {
    display: inline-block;
    position: relative;
    color: black;
    cursor: pointer;
}

.active::after {
    content: '';
    position: absolute;
    width: 85%;
    transform: scaleX(1);
    height: 2px;
    bottom: 0;
    left: 0.25;
    background-color: black;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}

.inactive {
    display: inline-block;
    position: relative;
    color: black;
    cursor: pointer;
}

.inactive::after {
    content: '';
    position: absolute;
    width: 85%;
    transform: scaleX(0);
    height: 1px;
    bottom: 0;
    left: 0.25;
    background-color: black;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}

.inactive:hover::after {
    transform: scaleX(1);
    height: 1px;
    transform-origin: bottom left;
}


@media screen and (max-width: 768px) {
    .home_head {
        flex-direction: column;
    }

    .home_head_name {
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .home_categories {
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        justify-content: left;
        overflow: auto;
        overflow-y: hidden;
        overscroll-behavior-inline: contain;
    }

    .home_gallery {
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
        grid-template-rows: repeat(auto-fill, minmax(300px, 1fr));
    }
}