.mantine-Modal-inner {
    padding-left: 0;
}

.gallery_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: var(--box-width);
    height: 100%;
    background-color: #fff;
    overflow: hidden;
    margin: 2.5rem 0rem;
    border-radius: var(--box-border-radius);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.55);

    transition: all 0.6s;
}

.gallery_item:hover {
    transform: scale(1.05);
}

.gallery_item_image {
    width: var(--box-width);
    height: var(--box-height);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.gallery_item_image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: var(--box-border-radius);
}

.gallery_item_info_bg {
    background-color: rgba(0, 0, 0, 0.4);
    width: var(--box-width);
    height: var(--box-height);
    border-radius: var(--box-border-radius);
    opacity: 0;
    position: absolute;
}

.gallery_item_winner {
    display: flex;
    justify-content: right;
    align-items: right;
    position: absolute;
    width: var(--box-width);
    height: var(--box-height);
}

.gallery_item_info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: var(--box-info-width);
    height: var(--box-info-height);
    max-height: var(--box-info-height);

    color: white;
    opacity: 0;
    position: absolute;
    filter: blur(5px);

    transition: all 0.5s;
}

.gallery_item_info .gallery_item_info_title p {
    font-size: 24px;
    font-weight: 700;
    line-height: 25px;
    text-transform: uppercase;
    margin: 0;
}

.gallery_item_info .gallery_item_info_date p {
    font-size: 11px;
    font-weight: 400;
    text-transform: uppercase;
    margin: 0;
    margin-top: 0.5px;
    margin-bottom: 0.5rem;
}

.gallery_item_info .gallery_item_info_description p {
    font-size: 14px;
    font-weight: 400;
    height: var(--box-info-description-height);
    width: var(--box-info-description-width);
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
    overflow-y: scroll;
    overflow-x: hidden;
}

/* hidden scrollbar for description */
.gallery_item_info .gallery_item_info_description p::-webkit-scrollbar {
    display: none;
}

/* categories for projects */
.gallery_item_info .gallery_item_info_categories p span {
    font-size: 11px;
    font-weight: 400;
    text-transform: uppercase;

    /* bubble effect */
    position: relative;
    display: inline-block;
    padding: 0.1rem 0.5rem;
    margin: 0.1rem 0.2rem;
    border-radius: 1rem;
    background-color: rgba(255, 255, 255, 0.2);
}

.gallery_item_info .gallery_item_info_url a {
    color: white;
    margin: 0.25rem;
}

.gallery_item:hover .gallery_item_info_bg,
.gallery_item:hover .gallery_item_info {
    opacity: 1;
    filter: blur(0px);
}

.gallery_item:hover .gallery_item_image {
    filter: blur(3px);
}

.hidden {
    display: none;
}